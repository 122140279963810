import { filter } from "lodash";
import { observer } from "mobx-react-lite";
import { Layout } from "../layout";
import { Page, products, races, Sex, state } from "../state";

export const PersonalInformationConfirmPage = observer(() => {
  return (
    <div className="form_check">
      <Layout title={"고객정보 확인"} step={1}>
        <p className="noti">입력하신 정보와 일치하는지 확인해 주세요.</p>
        <table className="table01">
          <caption>고객정보 확인</caption>
          <colgroup>
            <col style={{ width: "40%" }} />
            <col style={{ width: "60%" }} />
          </colgroup>
          <tbody>
            <tr>
              <th>성명</th>
              <td>{state.personalInformation.name}</td>
            </tr>
            <tr>
              <th>휴대폰번호</th>
              <td>{state.personalInformation.mobilePhoneNumber}</td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>{state.personalInformation.email}</td>
            </tr>
            <tr>
              <th>바코드번호</th>
              <td>{state.sampleId}</td>
            </tr>
            <tr>
              <th>검체 채취 일시</th>
              <td>
                {state.personalInformation.sampleDate.format(
                  "YYYY.MM.DD / A hh:mm"
                )}
                {/*2021.05.04 / AM 09:24*/}
              </td>
            </tr>
            <tr>
              <th>우편번호</th>
              <td> {state.personalInformation.address?.zonecode ?? ""}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td>
                {state.personalInformation.address?.address ?? ""}
                <br />
                {state.personalInformation.addressDetail}
              </td>
            </tr>
            <tr>
              <th>생년월일</th>
              <td>{state.personalInformation.birthday.format("YYYY.MM.DD")}</td>
            </tr>
            <tr>
              <th>성별</th>
              <td>
                {(() => {
                  switch (state.personalInformation.sex) {
                    case Sex.Male:
                      return "남자";
                    case Sex.Female:
                      return "여자";
                  }
                })()}
              </td>
            </tr>
            <tr>
              <th>인종</th>
              <td>
                {
                  filter(
                    races,
                    ([race, _]) => race === state.personalInformation.race
                  ).map(([_, label]) => label)[0]
                }
              </td>
            </tr>
            <tr>
              <th>검사 의뢰 상품</th>
              <td>
                {
                  filter(
                    products,
                    ([race, _]) => race === state.personalInformation.product
                  ).map(([_, label]) => label)[0]
                }
              </td>
            </tr>
            <tr>
              <th>게약번호</th>
              <td>{state.personalInformation.contractNumber}</td>
            </tr>
            <tr>
              <th>상담자</th>
              <td>{state.personalInformation.counselor}</td>
            </tr>
          </tbody>
        </table>
        <div className="btn-wrap">
          <a
            href="#"
            className="border-btn"
            onClick={(e) => {
              e.preventDefault();
              state.go(Page.PersonalInformation);
            }}
          >
            다시 쓰기
          </a>
          <a
            href="#"
            className="red-btn"
            onClick={(e) => {
              e.preventDefault();
              state.go(Page.Agreement);
            }}
          >
            계속하기
          </a>
        </div>
      </Layout>
    </div>
  );
});
