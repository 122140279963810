import "../pub/css/reset.css";
import "../pub/css/common.css";
import "../pub/css/style.css";
// 시간 관리 라이브러리 한국어로 초기화
import "moment/locale/ko";
import { IndexPage } from "./page";
import { observer } from "mobx-react-lite";
import { Page, state } from "./state";
import { DtcManualPage } from "./page/dtcManual";
import { PersonalInformationPage } from "./page/personalInformation";
import { PersonalInformationConfirmPage } from "./page/personalInformationConfirm";
import { SurveyPage } from "./page/survey";
import { AgreementPage } from "./page/agreement";
import { CompletePage } from "./page/complete";

export const App = observer(() => {
  switch (state.page) {
    case Page.Index: {
      return <IndexPage />;
    }
    case Page.DtcManual: {
      return <DtcManualPage />;
    }
    case Page.PersonalInformation: {
      return <PersonalInformationPage />;
    }
    case Page.PersonalInformationConfirm: {
      return <PersonalInformationConfirmPage />;
    }
    case Page.Agreement: {
      return <AgreementPage />;
    }
    case Page.Survey: {
      return <SurveyPage />;
    }
    case Page.Complete: {
      return <CompletePage />;
    }
  }
});
