import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { App } from "./questionnaire/app";
import { Environment, state } from "./questionnaire/state";

window.onbeforeunload = function () {
  if (state.onEditPage()) {
    return "입력 중이던 있을 경우 다시 입력하셔야 합니다.";
  }
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// TODO :: 성능 측정기 APM 추가 할것
if (state.environment === Environment.Production) {
  reportWebVitals(console.log);
}
