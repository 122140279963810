import { observer } from "mobx-react-lite";
import { Header, Layout } from "../layout";
import { state, SurveyQuestion } from "../state";

export const SurveyPage = observer(() => {
  return (
    <div className="survey">
      <Layout title={"건강 상태 조사/설문"} step={2}>
        <p className="color-grey">
          이 설문은 고객님의 현재 건강 상태를 파악하는 문항입니다. <br />
          고객님의 건강 상태에 해당하는 것을 YES / NO 형태로 체크해 주세요.
        </p>
        <div className="form01 type02">
          <dl>
            <dt>키/체중/허리둘레를 입력해주세요.</dt>
            <dd>
              <ul className="col3">
                <li>
                  <label>
                    <span className="name">키</span>
                    <input
                      type="text"
                      className="wFull"
                      maxLength={80}
                      value={state.height}
                      onChange={(e) => state.setHeight(e.target.value)}
                    />
                    <span className="unit">cm</span>
                  </label>
                </li>
                <li>
                  <label>
                    <span className="name">체중</span>
                    <input
                      type="text"
                      className="wFull"
                      maxLength={80}
                      value={state.weight}
                      onChange={(e) => state.setWeight(e.target.value)}
                    />
                    <span className="unit">kg</span>
                  </label>
                </li>
                <li>
                  <label>
                    <span className="name">허리둘레</span>
                    <input
                      type="text"
                      className="wFull"
                      maxLength={80}
                      value={state.waist}
                      onChange={(e) => state.setWaist(e.target.value)}
                    />
                    <span className="unit">cm</span>
                  </label>
                </li>
              </ul>
            </dd>
          </dl>
        </div>
        <div className="question">
          {state.surveyQuestions.map((surveyQuestion, index) => (
            <Question
              key={index}
              index={index}
              surveyQuestion={surveyQuestion}
            />
          ))}
        </div>
        <a
          href="#"
          className="red-btn"
          onClick={(e) => {
            e.preventDefault();
            const error = state.validateSurveyQuestion();
            if (error) {
              alert(error);
              return;
            }

            state.sendToServer();
          }}
        >
          제출하기
        </a>
      </Layout>
      <Header />
    </div>
  );
});

const Question = observer(
  (props: { index: number; surveyQuestion: SurveyQuestion }) => {
    return (
      <dl>
        <dt>{props.surveyQuestion.question}</dt>
        <dd>
          <span className="mon num">
            Q {(props.index + 1).toString().padStart(2, "0")}
          </span>
          <div className="radio_style3 mon strong">
            <input
              type="radio"
              id={`survey${props.index}_yes`}
              checked={props.surveyQuestion.answer === true}
              onChange={(e) => props.surveyQuestion.setAnswer(e.target.checked)}
            />
            <label htmlFor={`survey${props.index}_yes`}>
              <span>YES (예)</span>
            </label>
            <input
              type="radio"
              id={`survey${props.index}_no`}
              checked={props.surveyQuestion.answer === false}
              onChange={(e) =>
                props.surveyQuestion.setAnswer(!e.target.checked)
              }
            />
            <label htmlFor={`survey${props.index}_no`}>
              <span>NO (아니오)</span>
            </label>
          </div>
        </dd>
      </dl>
    );
  }
);
