// noinspection HttpUrlsUsage

import imagesTitlePng from "../pub/images/title.png";
import classNames from "classnames";
import { PropsWithChildren } from "react";

export const Header = () => {
  return (
    <header>
      <img src={imagesTitlePng} alt="" />
    </header>
  );
};

export const Footer = () => {
  return (
    <footer>
      <div className="inner">
        <div className="content">
          <p>(주)엔젠바이오 (질병관리청 제286호)</p>
          <address>
            대표 : 최대출 | 사업자 등록번호 : 123 - 45 - 56789
            <br />
            주소 : 서울특별시 구로구 디지털로 288 410호
            <br />
            대륭포스트타워 1차 410호
            <br />
            Tel : 02 - 867 - 9798 / Fax : 02 - 866 - 9784
          </address>
          <div className="link mon">
            <p>
              <a
                href="https://www.ngenebio.com/ko/"
                target="_blank"
                rel="noreferrer"
              >
                www.ngenebio.com
              </a>
            </p>
            <p>
              <a
                href="http://plus.genorhythm.com/"
                target="_blank"
                rel="noreferrer"
              >
                plus.genorhythm.com
              </a>
            </p>
          </div>
          <a href="#" className="link2">
            개인정보처리방침
          </a>
          <p className="copy mon">© 2021 NGeneBio. All Right Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export const Location = (props: { title: string; step: number }) => {
  return (
    <div className="location">
      <p>{props.title}</p>
      <ul className="mon step">
        <li className={classNames({ on: props.step === 1 })}>01</li>
        <li className={classNames({ on: props.step === 2 })}>02</li>
        <li className={classNames({ on: props.step === 3 })}>03</li>
      </ul>
    </div>
  );
};

export const Layout = (
  props: PropsWithChildren<{ title: string; step: number }>
) => {
  return (
    <>
      <Header />
      <div className="inner">
        <div className="content">
          <Location title={props.title} step={props.step} />
          {props.children}
        </div>
      </div>
      <Footer />
    </>
  );
};
