import { observer } from "mobx-react-lite";
import { Page, state } from "../state";

export const DtcManualPage = observer(() => {
  // noinspection HttpUrlsUsage
  return (
    <div className="detail">
      <header>
        <a
          href="#"
          className="back"
          onClick={(e) => {
            e.preventDefault();
            state.go(Page.Index);
          }}
        >
          이전페이지
        </a>
        <p>유전자 검사 서비스 설명문</p>
      </header>
      <div className="inner">
        <div className="content">
          <dl>
            <dt className="redbar">유전자 검사의 목적</dt>
            <dd>
              본 검사는 의료기관을 거치지 않고 소비자가 직접 유전자 검사기 관에
              의뢰하는 DTC유전자 검사로, 유전적 특성을 분석하여 체계 적으로
              건강관리를 할 수 있도록 맞춤형 가이드를 제공하는 웰니 스
              서비스입니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">검사 방법</dt>
            <dd>
              본 서비스는 개인의 특성과 연관되었다고 보고된 유전자 마커(SNP)를
              분석하고 그 결과에 따라 분석 알고리즘을 통해 결과값을 예측합니다.
              변이 영역 분석에는 의뢰자의 특정 유전자 영역만을 증폭시켜 변이를
              확인하는 SNP chip 분석 방법이 사용됩니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">유전자 검사기관 및 인력 정보</dt>
            <dd>
              검사기관 : (주)엔젠바이오 <br />
              주소 : (06390)서울특별시 구로구 디지털로 288, 대륭포스트타워 1차,
              303호~307호, 314호, 410호
              <br />
              인력 : 헬스케어 개발본부, 헬스케어 사업본부
            </dd>
          </dl>
          <dl>
            <dt className="redbar">검사 이용방법</dt>
            <dd>
              DTC 유전자검사는 본 서비스에 대해 충분히 이해하고, 유전자
              검사동의서, 개인정보 수집/이용 제공 동의서, 설문지를 작성하신 후에
              이용하실 수 있습니다. 동의서 작성 후 제공된 키트를 이용한 검체
              채취, DNA 해독 및 분석, 결과 제공 등이 서비스 범위에 해당됩니다.
              본 서비스는 검사 의뢰자가 미성년자일 경우 법정대리인 (부모 등)이
              윤리적, 법적 및 사회적 관점에서 동의해야 하며, 동의서에 서명해야
              합니다. 법정대리인의 동의를 받을 수 없는 경우, 본 서비스를 받을 수
              없습니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">검사대상물(검체)의 관리</dt>
            <dd>
              고객의 검체 및 유전정보는 개인정보 유출 방지시스템에 의해 엄격하게
              관리되고 있습니다. 검체는 "생명윤리 및 안전에 관한 법률" 제53조
              제3항에 따라 검사결과 획득 후 즉시 폐기되며, "폐기물관리법" 제13조
              '폐기물의 처리기준 등'에 따라 폐기됩니다. 폐기 시 잔여 검체는 전문
              폐기물 처리업체에서 수거하여 폐기합니다. 유전자 검사기관이 휴업
              또는 폐업이나 그 밖의 부득이한 사정으로 인하여 검사대상물을 보존할
              수 없는 경우에는 검체 및 유전정보가 포함된 개인정보는 폐기하거나
              질병관리본부 또는 다른 유전자 검사기관으로 이관됩니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">검사대상자의 권리</dt>
            <dd>
              본 검사는 의뢰자의 자율적 선택에 의해서만 신청할 수 있으며,
              의뢰자는 DNA 해독 및 분석이 진행되기 전까지 언제든지 검사 의뢰를
              취소할 수 있습니다. 의뢰자가 검사를 취소하면 해당 검체와 결과를
              포함한 모든 개인정보는 익명처리를 거쳐 폐기됩니다. 본 서비스의
              모든 절차는 동의를 득한 후 시작되며, 의뢰자는 동의를 거부할 권리가
              있습니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">유전자 정보의 관리</dt>
            <dd>
              유전자 검사 결과기록은 "생명윤리 및 안전에 관한 법률" 제52조 '기록
              보관 및 정보의 공개'에 따라 10년간 보관됩니다. 의뢰자가 개인정보
              및 유전자 검사 제공동의를 철회할 경우, 언제든지 동의 철회가
              가능하고 즉시 폐기됩니다. 본 기관은 '개인정보 보호법' 제23조 '민감
              정보의 처리 제한'에 따라 본 서비스를 통해 수집 및 생성된
              개인정보는 본 검사의 목적 이외에는 사용되지 않으며, 암호화, 익명화
              처리되어 엄격히 관리하며, 분실, 도난, 유출, 변조 또는 훼손되지
              않도록 안전하게 관리되고 있습니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">개인정보 처리방침</dt>
            <dd>
              본 기관은 개인정보의 안전한 보호를 위해 '개인정보 보호법'등 관련
              법률과 '표준 개인정보 보호지침'을 준수하고 있습니다. 기타 개인정보
              보호에 대한 상세 관리규정은 본 기관의 홈페이지를 통해 개인정보
              처리방침을 통해 확인할 수 있습니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">검사의 한계</dt>
            <dd>
              {" "}
              본 검사는 유전적 위험도를 예측하는 자료이며, 결과의 해석은 새로운
              정보가 추가됨에 따라 변경될 수 있습니다. 또한 유전자의 변이와
              표현형과의 뚜렷한 상관관계가 명확히 밝혀지지 않은 경우가 있다는
              한계가 있습니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">검사의 영향력</dt>
            <dd>
              본 검사에서 제공하는 결과는 해당 항목에 영향을 주는 환경 및
              생활습과 등의 다른 요인을 고려하지 않고 유전자형에만 근거하여
              분석하므로 현재의 상태와 다를 수 있습니다.'생명윤리 및 안전에 관한
              법률' 제46조 '유전정보에 의한 차별 금지 등'에 따라 DTC 유전자
              검사결과를 이유로 교육, 고용, 승진, 보험 등 사회활동에서 다른
              사람과 차별하여서는 안되며, 다른 법률에서 특별한 규정이 없는 한
              누구든지 타인에게 유전자 검사를 받도록 강요하거나 유전자 검사의
              결과를 제출하도록 강요하여서는 안됩니다. 질병의 진단 및 치료의
              목적으로 사용될 수 없으며 의학적인 소견이 필요한 경우 의료기관을
              통해 상담하시길 바랍니다. 본 검사는 유전질환 검사가 아닌 개인의
              유전적 특성을 해석하는 검사로 그 결과가 가족의 현재 및 미래에
              미치는 특별한 영향은 없습니다. 본 검사 결과와 연계하여 특정 상품의
              판매를 강요하거나, 유인 또는 강압하지 않으며, 연계된 상품이 있는
              경우 그 정보를 투명하게 제공합니다.
            </dd>
          </dl>
          <dl>
            <dt className="redbar">
              검사 항목 및 과학적 근거, 검사 서비스 상품의 종류
            </dt>
            <dd>
              본 기관에서 제공하는 유전자 검사 서비스의 종류와 검사항목,
              항목설명 및 항목별 과학적 근거는 본 기관의 홈페이지를 통해
              확인하실 수 있습니다.
              <br />
              정보 확인 웹사이트 주소 :{" "}
              <a
                href="http://plus.genorhythm.com/"
                rel="noreferrer"
                target="_blank"
                className="red line"
              >
                plus.genorhythm.com
              </a>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
});
