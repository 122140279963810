import { BaseSyntheticEvent } from "react";

export function comingSoon(e: BaseSyntheticEvent) {
  e.preventDefault();
  alert("준비중입니다.");
}

export function numberOnly(str: string): string {
  return str.trim().replaceAll(/[^0-9]/gi, "");
}
