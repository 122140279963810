import imagesMainTitle from "../../pub/images/main_title.png";
import imagesMainImg from "../../pub/images/main_img.png";
import { observer } from "mobx-react-lite";
import { comingSoon } from "../../utils";
import { Page, state } from "../state";

export const IndexPage = observer(() => (
  <div className="main">
    <div className="content">
      <h1>
        <img src={imagesMainTitle} alt="CERACHECK DNA 유전자 검사 동의서" />
      </h1>
      <img src={imagesMainImg} alt="" />
      <a
        href="#"
        className="red-btn"
        onClick={(e) => {
          e.preventDefault();
          if (state.sampleId.length === 0) {
            alert(
              "검체 번호를 확인할 수 없습니다.\nQR 코드를 확인하여 주십시오."
            );
            return;
          }
          state.go(Page.PersonalInformation);
        }}
      >
        시작하기
      </a>
    </div>
  </div>
));
